import { Grid } from "@mui/material";
import React from "react";
import Collection from "../curate/Collection";

export default function ProfilePicks({ top1List, top3List, top5List }) {
  return (
    <Grid
      container
      sx={{ pb: 2, px: { xs: 4, sm: 0 } }}
      spacing={{ xs: 3, sm: 2 }}
    >
      <Grid item xs={12} sm={4}>
        <Collection
          display="inline"
          list={top1List}
          backgroundColor={(theme) => theme.palette.secondary.main}
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <Collection
          display="inline"
          list={top3List}
          backgroundColor={(theme) => theme.palette.secondary.main}
        />
      </Grid>

      <Grid item xs={12}>
        <Collection
          display="inline"
          list={top5List}
          backgroundColor={(theme) => theme.palette.secondary.main}
        />
      </Grid>
    </Grid>
  );
}
