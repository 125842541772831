const isInAppBrowser = () => {
  const userAgent = (
    navigator.userAgent ||
    navigator.vendor ||
    window.opera
  ).toLowerCase();
  const isInstagram = userAgent.includes("instagram");
  const isFacebook = userAgent.includes("fban") || userAgent.includes("fbav");
  const isLinkedIn = userAgent.includes("linkedin");
  const isTikTok = userAgent.includes("tiktok");
  const isTwitter = userAgent.includes("twitter");
  const isReddit = userAgent.includes("reddit");
  const isSnapchat = userAgent.includes("snapchat");
  const isPinterest = userAgent.includes("pinterest");
  return (
    isInstagram ||
    isFacebook ||
    isLinkedIn ||
    isTikTok ||
    isTwitter ||
    isReddit ||
    isSnapchat ||
    isPinterest
  );
};

export default isInAppBrowser;
