// External imports
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  ToggleButton,
  Container,
  useTheme,
  Stack,
  Avatar,
  Typography,
  Tabs,
  Tab,
  Grid,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import PeopleIcon from "@mui/icons-material/People";
import { useQuery } from "@tanstack/react-query";

// Internal imports
import HideOnScroll from "../../components/HideOnScroll.js";
import { useAuth } from "../../context/authContext.js";
import Bio from "./Bio.js";
import { formatDate, getStatusOptions } from "../../utils/utils.js";
import useApi from "../../hooks/useApi.js";
import Loading from "../../components/Loading.js";

const ProfileHeader = ({ toggleDrawer, activeTab, handleTabChange }) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { fetchUserCounts } = useApi();
  const [bioAnchorEl, setBioAnchorEl] = useState(null);
  const openBio = Boolean(bioAnchorEl);
  const drawerWidth = 240;
  const statusOptions = getStatusOptions(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const userCounts = useQuery({
    queryKey: ["userCounts", user.id],
    queryFn: () => fetchUserCounts(),
    enabled: true,
  });

  const handleBioOpen = (event) => {
    setBioAnchorEl(event.currentTarget);
  };

  const handleBioClose = () => {
    setBioAnchorEl(null);
  };

  if (userCounts.isPending) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (userCounts.isError) {
    return (
      <div>
        <Typography>{userCounts.error?.message}</Typography>
      </div>
    );
  }

  const tabData = [
    {
      label: "Movies",
      value: "movie",
      icon: <MovieRoundedIcon />,
      count: userCounts.data.content.movie?.count.total || "--",
    },
    {
      label: "Books",
      value: "book",
      icon: <MenuBookRoundedIcon />,
      count: userCounts.data.content.book?.count.total || "--",
    },
    {
      label: "Podcasts",
      value: "podcast",
      icon: <PodcastsRoundedIcon />,
      count: userCounts.data.content.book?.count.total || "--",
    },
    {
      label: "TV",
      value: "TV",
      icon: <LiveTvRoundedIcon />,
      count: userCounts.data.content.book?.count.total || "--",
    },
  ];

  return (
    <>
      <Toolbar sx={{ height: { xs: "250px", md: "200px" } }} />
      {/* The above toolbar acts as a spacer, whatever padding is applied to the below appbar
      needs to be applied as margin to the above spacer, added 0.5 to give space to show librarycard hover scale effects */}
      <HideOnScroll>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            // backgroundColor: "white",
            width: { md: `calc(100% - ${drawerWidth}px )` },
            ml: { md: `${drawerWidth}px` },
            pt: 3,
            pb: 3,
            overflowX: "auto",
            backgroundImage: "none",
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{
                    borderBottom: { xs: 0, md: 1 },
                    borderColor: { xs: undefined, md: "divider" },
                    height: "110px", // 90px of avatar + 20px gap between avatar and divider
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ToggleButton
                    // This is the menu button which only displays when the sidebar is hidden
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleDrawer}
                    size="small"
                    value="menu"
                    sx={{
                      mr: 2,
                      display: { md: "none" },
                      alignSelf: "center",
                      "&.MuiButtonBase-root.MuiToggleButton-root": {
                        border: "none",
                        borderRadius: 1,
                      },
                    }}
                  >
                    <MenuIcon />
                  </ToggleButton>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    flexGrow={1}
                  >
                    <Avatar
                      alt={user.givenName}
                      src={user?.picture}
                      sx={{
                        width: 90,
                        height: 90,
                        cursor: isMobile && "pointer",
                        bgcolor: "tertiary.main",
                      }}
                      onClick={isMobile ? handleBioOpen : undefined}
                    />
                    <Stack direction="column" spacing={0.5}>
                      <Typography>
                        <b>{user.givenName}</b> <i>@{user.username}</i>
                      </Typography>
                      {!isMobile && (
                        <Typography
                          sx={{
                            textWrap: "balance",
                            display: { xs: "none", lg: "block" },
                          }}
                        >
                          {user.bio}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      height: "100%",
                    }}
                  >
                    <Tabs
                      value={activeTab}
                      onChange={handleTabChange}
                      aria-label="Profile content tabs"
                    >
                      {tabData.map((tab, index) => (
                        <Tab
                          key={index}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {tab.icon}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {tab.count}
                              </Typography>
                            </Box>
                          }
                          value={tab.value}
                          disabled={tab.value !== "movie"}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  display="flex"
                  justifyContent="space-between"
                >
                  {!isMobile && (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ pt: 1.5, alignItems: "center" }}
                    >
                      <CalendarMonthIcon />
                      <Typography sx={{ pr: 1 }}>
                        {" "}
                        Curating since{" "}
                        {formatDate(
                          userCounts.data.content.movie.started_timestamp,
                          "dropDay"
                        )}
                      </Typography>
                      {/* <PeopleIcon />
                    <Typography sx={{ pr: 1, color: "red" }}>
                      {" "}
                      <b>299</b> followers
                    </Typography>
                    <PeopleIcon />
                    <Typography sx={{ pr: 1, color: "red" }}>
                      {" "}
                      <b>30</b> following
                    </Typography> */}
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ pt: 1.5, alignItems: "center" }}
                    justifyContent={{ md: "flex-end" }}
                  >
                    {React.cloneElement(statusOptions.queued.icon, {
                      sx: { color: statusOptions.queued.color },
                    })}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {userCounts.data.content.movie.count.queued}{" "}
                    </Typography>
                    {React.cloneElement(statusOptions.started.icon, {
                      sx: { color: statusOptions.started.color },
                    })}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {userCounts.data.content.movie.count.started}{" "}
                    </Typography>
                    {React.cloneElement(statusOptions.abandoned.icon, {
                      sx: { color: statusOptions.abandoned.color },
                    })}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {userCounts.data.content.movie.count.abandoned}{" "}
                    </Typography>
                    {React.cloneElement(statusOptions.finished.icon, {
                      sx: { color: statusOptions.finished.color },
                    })}
                    <Typography sx={{ pr: 1 }}>
                      {" "}
                      {userCounts.data.content.movie.count.finished}{" "}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>

          <Bio
            anchorEl={bioAnchorEl}
            open={openBio}
            onClose={handleBioClose}
            content={user.bio}
          />
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default ProfileHeader;
