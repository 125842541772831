import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import App from "./app.js";
import {
  ThemeContextProvider,
  useThemeContext,
} from "./context/themeContext.js";
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5 * 60 * 1000 } },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (error.message === "Network Error" || error.code === "ECONNREFUSED") {
        toast.error(
          `The Curate servers are currently down for maintenance. Please try again later.`,
          { autoClose: false }
        );
        window.location.href = "/";
      } else {
        toast.error(
          `Something went wrong: ${
            error.response?.data?.message || error.message
          }`,
          { autoClose: false }
        );
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById("root"));

function Root() {
  const { mode } = useThemeContext();
  let theme = createTheme({
    palette: {
      mode,
      primary: {
        main: "#00d37e",
        contrastText: "#fff",
      },
      secondary: {
        main: mode === "dark" ? "#1D3639" : "#f0f0f0",
        contrastText: mode === "dark" ? "#fff" : "#122425",
      },
      tertiary: {
        main: "#b1a7ec",
        contrastText: "#fff",
      },
      background: {
        default: mode === "dark" ? "#122425" : "#fff",
        paper: mode === "dark" ? "#122425" : "#fff",
      },
      text: {
        primary: mode === "dark" ? "#fff" : "#122425",
        // secondary: "#555555", // Secondary text color
      },
      status: {
        queued: {
          main: "#b1a7ec",
          contrastText: "#fff",
        },
        started: {
          main: "#9ee7b9",
          contrastText: "#333",
        },
        abandoned: {
          main: "#f27c75",
          contrastText: "#fff",
        },
        finished: {
          main: "#00d37e",
          contrastText: "#fff",
        },
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none", // Remove capitalisation
            boxShadow: "none", // Remove box shadow
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ToastContainer
          position={isMobile ? "bottom-center" : "top-right"}
          autoClose={2000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={mode}
        />
        <QueryClientProvider client={queryClient}>
          <App />
          {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

root.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <Root />
    </ThemeContextProvider>
  </React.StrictMode>
);
