import React from "react";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";

const ListMenu = ({ anchorEl, onClose, onSelect, options }) => (
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
    slotProps={{
      paper: {
        elevation: 0,
        sx: {
          maxHeight: 300, // Set a maximum height
          overflow: "auto",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", // adds shadow around menu
          mt: 1.5,
        },
      },
    }}
  >
    {options.map((option) => (
      <MenuItem
        key={option.value}
        onClick={(event) => onSelect(option.value)(event)}
        disabled={option?.disabled}
        dense
      >
        <ListItemIcon sx={{ color: option?.color }}>
          {option?.icon}
        </ListItemIcon>
        {option.label}
      </MenuItem>
    ))}
  </Menu>
);

export default ListMenu;
